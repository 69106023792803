.feedback__column--2ts54:last-child > :last-child {
  display: none; }

@media (min-width: 990px) {
  .feedback__column--2ts54 > :last-child {
    display: none; } }

.feedback__unit--nimEN {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 10%); }
  .feedback__unit--short--zDJ3w {
    cursor: pointer;
    -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0); }

.feedback__header--1E9vm {
  --rating-color: var(--color-primary);
  border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
  .feedback__header-content--2w5qv {
    display: flex;
    align-items: center; }
  .feedback__header-rating--2dxF0 {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    color: var(--rating-color); }
  .feedback__header-icon--1BGuK {
    --svg-primary-color: var(--rating-color);
    position: relative;
    top: -1px;
    margin-right: 5px; }

.feedback__content-text--3au0h {
  display: flex;
  white-space: pre-line; }

.feedback__button--DmSU6 {
  --svg-primary-color: hsla(0, 0%, 65%, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsla(0, 0%, 65%, 1); }
